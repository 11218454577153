























































































import { InputSetups } from '@/mixins/input-setups'
import Slots from '@/components/Slots.vue'
import EditorButtonsHelpView from '@/components/EditorButtonsHelpView.vue'
import { UserActivationActionMessage, UserActivationActionType } from '@/includes/types/PmConfig.types'

import { UseFields } from 'piramis-base-components/src/components/Pi'
import ConfigField from 'piramis-base-components/src/components/ConfigField/ConfigField.vue'
import { EntityTypes } from 'piramis-base-components/src/components/SelectEntityWizard/includes/types'
import ActivationActionData
  from 'piramis-base-components/src/components/BotFeedback/PrivateMessageSettings/components/ActivationActionData.vue'
import HighlightAnchor from 'piramis-base-components/src/components/HighlightAnchor.vue'
import PageTitle from 'piramis-base-components/src/components/PageTitle.vue'

import Component from 'vue-class-component'
import { Mixins } from 'vue-property-decorator'

@Component({
  components: {
    Slots,
    PageTitle,
    ConfigField,
    ActivationActionData,
    HighlightAnchor
  },
  data() {
    return {
      EditorButtonsHelpView,
      EntityTypes
    }
  }
})
export default class ChannelSettings extends Mixins<InputSetups, UseFields>(InputSetups, UseFields) {

  actionSet = false

  get channel() {
    return this.$store.state.channelsState.activeChannel
  }

  setGoodbuyAction() {
    if (this.channel && !this.channel.config?.goodbuy_action) {
      const goodbuy_message = this.channel.config?.goodbuy_message

      if (goodbuy_message && goodbuy_message.length > 0) {
        const activationActionData: UserActivationActionMessage = {
          type: UserActivationActionType.Message,
          variants: goodbuy_message
        }

        this.$set(this.channel?.config, 'goodbuy_action', activationActionData)
      } else {
        this.$set(this.channel?.config, 'goodbuy_action', null)
      }
    }

    this.actionSet = true
  }

  created() {
    this.setGoodbuyAction()
  }

  gotoFlows(): void {
    this.$router.push({
      name: 'Flows_List',
      params: {
        [ EntityTypes.BOT_ID ]: this.$route.params[ EntityTypes.BOT_ID ]
      }
    })
  }
}
