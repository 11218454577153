

















































































































































import { Board, TargetState, TestChannelStateCode } from '@/includes/types/Board.types'
import { hasChangeConfigPermission } from '@/includes/PermissionHelper'
import TestStateMixin from '@/mixins/TestStateMixin'
import TestStatusInfo from '@/components/TestStatusInfo.vue'

import AListCustom from 'piramis-base-components/src/components/AListCustom/AListCustom.vue'
import AListCustomItem from 'piramis-base-components/src/components/AListCustom/AListCustomItem.vue'
import { UseFields } from 'piramis-base-components/src/components/Pi'
import ClipboardCopyMixin from 'piramis-base-components/src/logic/helpers/ClipboardCopyMixin'
import { EntityTypes } from 'piramis-base-components/src/components/SelectEntityWizard/includes/types'
import HighlightAnchor from 'piramis-base-components/src/components/HighlightAnchor.vue'
import PageTitle from 'piramis-base-components/src/components/PageTitle.vue'
import { atSignedLogin, tgLoginLink } from 'piramis-base-components/src/shared/utils/tgLoginHelpers'
import ConfigField from 'piramis-base-components/src/components/ConfigField/ConfigField.vue'

import Component from 'vue-class-component'
import { Mixins } from 'vue-property-decorator'
import moment from 'moment'

@Component({
  components: {
    PageTitle,
    AListCustom,
    AListCustomItem,
    TestStatusInfo,
    HighlightAnchor,
    ConfigField
  },
  data() {
    return {
      TargetState,
      hasChangeConfigPermission,
      moment,
      atSignedLogin,
      tgLoginLink,
      EntityTypes
    }
  }
})
export default class ChannelDetailedInformation extends Mixins<UseFields, TestStateMixin, ClipboardCopyMixin>(UseFields, TestStateMixin, ClipboardCopyMixin) {
  get channel() {
    return this.$store.state.channelsState.activeChannel
  }

  get board(): Board | null {
    return this.$store.getters.activeBoard
  }

  get getChannelState(): string {
    if (this.channel.state === TargetState.Log) {
      return this.$t('target_state_log').toString()
    }

    return this.$t('target_state_board').toString()
  }

  handleTestChannelStatusClick(event: Event): void {
    if (this.buttonDisabled) {
      event.preventDefault()
    } else {
      this.testChannelStatus(this.board!.board, this.channel.id)
    }
  }

  created() {
    if (this.board && this.channel && this.channel.test_state === TestChannelStateCode.None) {
      this.testChannelStatus(this.board.board, this.channel.id)
    }
  }
}
