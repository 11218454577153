

















import Component from 'vue-class-component'
import Vue from 'vue'
import { Prop, VModel } from 'vue-property-decorator'

@Component({})
export default class TestStatusInfo extends Vue {
  @VModel() isPopupOpen!: boolean

  @Prop() info!: any
}
